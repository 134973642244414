/** ADIACENT G.Grandinetti  */
import Axios, { AxiosPromise } from "axios";

import {
    localStorageUtil,
    sessionStorageUtil
} from "../_foundation/utils/storageUtil";
import { LOCALE } from "../_foundation/constants/common";

import {
    CommerceEnvironment
} from "../constants/common";

import { SiteInfo } from "../redux/reducers/reducerStateInterface";

const XSTORE_LOAD = "XSTORE_LOAD";

const xstoreconfiguration = {

    retrievePublicConfiguration(mySite: SiteInfo): boolean {
        let result = false;

        const isLoaded = sessionStorageUtil.get(XSTORE_LOAD); //check if loaded and the load 

        const locale = localStorageUtil.get(LOCALE);
        const langId = locale === null ?
                        CommerceEnvironment.languageMap[mySite.defaultLanguageID]
                        :
                        CommerceEnvironment.reverseLanguageMap[locale]
        // eslint-disable-next-line 
        if( isLoaded && (isLoaded.split("|")[1]?.trim() == langId) ) {
             return true;
        }

        xstoreconfiguration.retrievePublicConfiguration_promise(mySite.storeID, langId)
            .then((res) => {
                result = xstoreconfiguration.updateSessionStorage(res, langId);
            })
            .catch((ex) => {
                console.error("Eccezione caricamento XSTORECONF data");
                console.error(ex);
                result = false
            }
        );
        return result;
    },

    retrievePublicConfiguration_promise(storeId, langId): AxiosPromise {
        return  Axios.get(`/wcs/resources/store/${storeId}/xconfiguration?langId=${langId}`)          
    },

    updateSessionStorage(response, langId) : boolean {
        if(response?.status !== 200) return false;
        const xconfiguration = response.data?.xconfiguration;
        if (xconfiguration) {
            for (const conf in xconfiguration) {
                sessionStorageUtil.set(conf, xconfiguration[conf]);
            }
            sessionStorageUtil.set(XSTORE_LOAD, `${(Date.now()/1000 | 0)}| ${langId}`);
            return true;
        }
        return false;
    }

}

export default xstoreconfiguration;
/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { CONSTANTS } from "../../constants/order-item-table";
import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
import { INVENTORY_STATUS , NO_PLANT_SELECT} from "../../constants/order";
import { PAGINATION_CONFIGS } from "../../configs/order";
import { FunctionalAttrib, productAttributeUtils } from "./utils/productAttributeUtils";
//Redux
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import {
  forUserIdSelector,
  loginStatusSelector,
  userIdSelector,
  preferredCurrencySelector
} from "../../redux/selectors/user";
import { mroContextCountrySelector } from "../../redux/selectors/context";
//UI
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
  StyledAvatar,
  StyledGrid,
  StyledTypography,
  StyledNumberInput,
  StyledIconButton,
  TableColumnDef,
  StyledDialog,
  StyledDialogContent,
  StyledButton,
  StyledDialogTitle,
} from "@hcl-commerce-store-sdk/react-component";
import { makeStyles } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';


//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector } from "../../redux/selectors/order";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { XS_MOBILE_W } from "../../constants/common";
import { useTheme } from "@material-ui/core";

import noImageAvailable from '../../static/images/no-image-available.png'

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const useOrderItemTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];
  const contractId = useSelector(currentContractIdSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const mroCountry:string = useSelector(mroContextCountrySelector);
  const userPreferredCurrency = useSelector(preferredCurrencySelector);
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;

  const theme = useTheme();

  const dataProps = props.data;
  
  /**
   * Initialize table data by making a copy
   * Material-table alters the input data, so data cannot be of immutable type
   * @returns Copy of the data prop
   */
  const data = useMemo(() => {
    const newData = (dataProps ?? []).map((oi) => ({ ...oi }));

    const p = newData.map(i => {
      let manufacturerCode;
      let vendor;
      let vendorDesc;
      let mfPartNum;
      let minQt;
      let leadTIme;
      let commentObj = JSON.parse(i.comments);

      i.attributes.forEach(attr => {
        if(attr.usage === FunctionalAttrib.DESCRIPTIVE) {
          switch(attr.identifier)  {
            case FunctionalAttrib.CODICEFORNITOREECC: manufacturerCode = attr.values[0].value;break;
            case FunctionalAttrib.VENDOR_CODE: vendor = attr.values[0].value;break;
            case FunctionalAttrib.VENDOR_DESC: vendorDesc = attr.values[0].value;break;
            case FunctionalAttrib.MNF_PARTNUM: mfPartNum = attr.values[0].value;break;
            case FunctionalAttrib.MINIMUM_QUANTITY: minQt = productAttributeUtils.getVendorMQ(attr, mroCountry);break;
            case FunctionalAttrib.LEAD_TIME: leadTIme = productAttributeUtils.getVendorLT(attr, mroCountry);break;
          }
        }
      });

      i['mfPartNum'] = `${mfPartNum}`;
      i['vendor'] = `${vendor}`;
      i['vendorDesc'] = `${vendorDesc}`;
      i['preferred'] = productAttributeUtils.isDealerPreferred(manufacturerCode, vendor, mroCountry);
      i['global'] = productAttributeUtils.isDealerGlobal(manufacturerCode, vendor, mroCountry);
      i['minQt'] = (minQt && !isNaN(minQt) && minQt > 0) ? minQt : 1;
      i['leadTime'] = leadTIme ? productAttributeUtils.getDeliveryDate(leadTIme) : undefined;
      i['commentObj'] = commentObj;
      i['itemNote'] = commentObj?.comment;
      i['hasNote'] = commentObj?.comment && commentObj.comment.length > 0;

      return i;
    })

    return p;
  }, [dataProps, mroCountry]);

  const readOnly = props.readOnly !== undefined ? props.readOnly : true;
  const miniCartView =
    props.miniCartView !== undefined ? props.miniCartView : false;

  const defaultCurrencyID: string = userPreferredCurrency ? userPreferredCurrency : (mySite ? mySite.defaultCurrencyID : "");
  //update this flag as need later
  const pagination = !readOnly && !miniCartView;
  const handleMiniCartClose =
    props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
  /**
   * Initialize quantity data per order item
   * @returns quantities object for each order item
   */
  const initQuantityData = () => {
    let newData: any = {};
    if (dataProps) {
      dataProps.map((oi) => {
        if (oi.quantity) {
          try {
            const parsedQty = parseInt(oi.quantity);
            if (parsedQty > 0) {
              newData[oi.orderItemId] = parsedQty;
            }
          } catch (e) {
            console.log("Could not parse quantity");
          }
        }
        return null;
      });
    }
    return newData;
  };

  const [quantityList, setQuantityList] = useState<any>(initQuantityData());

  const defaultOptions = {
    toolbar: false,
    header: !miniCartView,
    paging: pagination,
    pageSize: PAGINATION_CONFIGS.pageLimit,
    pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
    actionsColumnIndex: -1,
    fixedColumns: {
      left: 0,
      right: 0,
    },
  };
  const options = props.options !== undefined ? props.options : defaultOptions;

  const columns = useMemo(() => {
    let cancels: Canceler[] = [];
    const payloadBase: any = {
      currency: defaultCurrencyID,
      contractId: contractId,
      widget: "Order Item Table",
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    const itemValCalc = ({ rowData: r }) => r.name || r.partNumber;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const oaValCalc = ({ rowData: r }) => {
      return r.availableDate === ""
        ? r.orderItemInventoryStatus === INVENTORY_STATUS.available ||
          r.orderItemInventoryStatus === INVENTORY_STATUS.allocated
          ? t("CommerceEnvironment.inventoryStatus.Available")
          : t("CommerceEnvironment.inventoryStatus.OOS")
        : r.availableDate <= new Date()
        ? t("CommerceEnvironment.inventoryStatus.Available")
        : r.orderItemInventoryStatus !== INVENTORY_STATUS.backordered
        ? t("CommerceEnvironment.inventoryStatus.Available")
        : t("CommerceEnvironment.inventoryStatus.Backordered");
    };

    const priceCalc = ({ rowData: r }) => Number(r.orderItemPrice);

    const quantityCalc = ({ rowData: r }) =>
      Number(quantityList[r.orderItemId]);

    const QuantityDisplay = (props: any) => (
      <StyledTypography>
        {quantityList[props.rowData.orderItemId]}
      </StyledTypography>
    );

    const OrderItemPrice = (props: any) => (
      <StyledTypography align={miniCartView ? "right" : "inherit"}>
        <FormattedPriceDisplay
          min={parseFloat(props.rowData.orderItemPrice)}
          currency={props.rowData.currency}
        />
      </StyledTypography>
    );

    const ThumbnailCell = ({ rowData, ...props }) => {
      let dimension = {};
      if(miniCartView )   {
        dimension= {width: theme.spacing(5) , height: theme.spacing(5)};
      }
      let thumb = rowData.thumbnail ? rowData.thumbnail : noImageAvailable;
      return (
        <>
          {rowData.seo && rowData.seo.href ? (
            <Link
              to={rowData.seo?.href}
              onClick={handleMiniCartClose ? handleMiniCartClose : null}>
              <StyledAvatar
                variant="rounded"
                alt={rowData.name}
                src={thumb}
                style={{ margin: "0", justifyContent: "flex-start", ...dimension }}
              />
            </Link>
          ) : (
            <StyledAvatar variant="rounded" 
                          alt={rowData.name} 
                          src={thumb}
                          style={{ margin: "0", justifyContent: "flex-start"}}
                           />
          )}
        </>
      );
    };

    const QuantityCell = ({ rowData, ...props }) => {
      const forUserId = useSelector(forUserIdSelector);
      const uId = useSelector(userIdSelector);
      const userId = forUserId ?? uId;
      const cart = useSelector(cartSelector);
      const itemMemberId = rowData.xitem_memberId;
      const { w } = useWinDimsInEM();
      const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined;
      const disabled =
        rowData.freeGift === "true" ||
        (cart?.buyerId !== userId && userId !== itemMemberId);

      /**
       * Dispatch quantity update action for order item
       * @param item The selected order item
       */
      const onQuantityUpdate = (quantityString: string, item: any) => {
        if (item) {
          try {
            const q = parseInt(quantityString);
            const minQt = rowData.minQt;
            const quantity:number = (minQt && !isNaN(minQt) && minQt > 0 && q % minQt !== 0) ? minQt : q;
            if (quantity > 0) {
              let payload = {
                ...payloadBase,
                quantity: quantity.toString(),
                orderItemId: item.orderItemId,
                fetchCatentries: true,
              };
              dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

              // update this (even tho it's temporary) just so that once the dispatch
              //   action completes, we don't "temporarily" revert back to the old value
              quantityList[rowData.orderItemId] = quantity;
            }
          } catch (e) {
            console.log("Could not parse quantity");
          }
        }
      };
      return !miniCartView && readOnly ? (
        <QuantityDisplay rowData={rowData} />
      ) : (
        <StyledNumberInput
          mobile={mobile}
          value={quantityList[rowData.orderItemId]}
          min={1}
          step={rowData.minQt}
          precision={0}
          disabled={disabled}
          onChange={(event) => onQuantityUpdate(event, rowData)}
          stopLoadingOnUpdateValue={rowData}
          debounceTiming={250}
          strict
        />
      );
    };

    const ItemDetailsCell = ({ rowData, ...props }) => {
      return (
        <>

          <StyledGrid
            container
            justifyContent="space-between"
            wrap={miniCartView ? "nowrap" : undefined}
            spacing={0}>
              
            <StyledGrid item>
              <StyledTypography variant="body2" noWrap={false} style={{wordBreak: "break-word", overflowWrap: 'break-word'}}>
                {rowData.seo && rowData.seo.href ? (
                  <Link
                    to={rowData.seo?.href}
                    onClick={handleMiniCartClose ? handleMiniCartClose : null}>
                    {rowData.name ? rowData.name : rowData.partNumber}
                  </Link>
                ) : rowData.name ? (
                  rowData.name
                ) : (
                  rowData.partNumber
                )}
              </StyledTypography>
              
              <StyledTypography variant="body1" noWrap={false} style={{wordBreak: "break-word",overflowWrap: 'break-word'}}>            
                {!miniCartView && `${t("productDetail.Vendor")} : `} {rowData.vendor}          
              </StyledTypography>
             
              {rowData.commentObj && rowData.commentObj.plant !== NO_PLANT_SELECT &&(
                <StyledTypography variant="body1" noWrap={false} style={{wordBreak: "break-word",overflowWrap: 'break-word'}}>
                  { miniCartView ? rowData.commentObj.plant :  `Plant: ${rowData.commentObj.plant}  ${rowData.commentObj.percentage}%` }
                </StyledTypography>
              )}

              <StyledTypography variant="body1" 
                                noWrap={false} 
                                style={{wordBreak: "break-word",overflowWrap: 'break-word', color: rowData.leadTime ? 'green' : 'orange'}}>
                {!miniCartView && `${t("productDetail.productLeadTime")} : ${rowData.leadTime ? rowData.leadTime : t("productDetail.productMissingLeadTime")}`}
              </StyledTypography>
              {/*!miniCartView &&
                rowData.attributes &&
                rowData.attributes.map(
                  (attribute: any, index: number) =>
                    attribute.values &&
                    attribute.values.map((value: any, index: number) => (
                      <StyledTypography variant="body1" key={value.id}>
                        {attribute.name}: {value.value}
                      </StyledTypography>
                    ))
                )*/}
              {rowData.freeGift === "true" && (
                <StyledTypography variant="overline" color="textSecondary">
                  {t("OrderItemTable.Labels.Gift")}
                </StyledTypography>
              )}
              {/*!miniCartView &&
                isRecurringOrderFeatureEnabled &&
                rowData.disallowRecurringOrder === "1" && (
                  <StyledTypography variant="overline" color="textSecondary">
                    {t("OrderItemTable.Labels.NonRecurring")}
                  </StyledTypography>
                )*/}
            </StyledGrid>
            {miniCartView ? (
              <StyledGrid item>
                <DeleteActionCell {...{ rowData }} />
              </StyledGrid>
            ) : null}
          </StyledGrid>
          
          {miniCartView && (
            <StyledGrid
              container
              alignItems="center"
              justifyContent="space-between">
              <StyledGrid item xs={12} sm className="qty-price-section">
                <QuantityCell {...{ rowData }} />
              </StyledGrid>
              <StyledGrid item xs className="qty-price-section">
                <OrderItemPrice rowData={rowData} />
              </StyledGrid>
            </StyledGrid>
          )}
          
        </>
      );
    };

    const NoteCell = ({ rowData, ...props }) => {
      const [isOpen, setIsOpen] = useState<boolean>(false);

      const useStyles = makeStyles({
        noteDialog: {
          minWidth: '400px',
          minHeight: '150px'
        },
      });
      const classes = useStyles();

      return (!miniCartView && rowData.hasNote) ? 
      (
        <>
          <CommentIcon onClick={()=>{setIsOpen(true)}}/>
          <StyledDialog
            open={isOpen}
            onClose={()=>setIsOpen(false)}
            className={classes.noteDialog}
            aria-labelledby="change-password-success-dialog">
            <StyledDialogTitle title="Order Item Note" onClickHandler={()=>setIsOpen(false)} />
            <StyledDialogContent className={classes.noteDialog}>{rowData.itemNote}</StyledDialogContent>
            <StyledButton
              variant="text"
              color="secondary"
              onClick={()=>setIsOpen(false)}>
              Close
            </StyledButton>
          </StyledDialog>
        </>
      ):null;
    };

    const DeleteActionCell = ({ rowData, ...props }) => {
      const cart = useSelector(cartSelector);
      const forUserId = useSelector(forUserIdSelector);
      const uId = useSelector(userIdSelector);
      const userId = forUserId ?? uId;
      const itemMemberId = rowData.xitem_memberId;
      const [clicked, setClicked] = useState<boolean>(false);
      const disabled =
        clicked ||
        rowData.freeGift === "true" ||
        (cart?.buyerId !== userId && userId !== itemMemberId);

      /**
       * Dispatch action to remove selected order item
       * @param item The selected order item
       */
      const removeItem = (item: any) => {
        const orderItemId = item.orderItemId;
        let payload = {
          ...payloadBase,
          orderItemId: orderItemId,
          fetchCatentries: true,
        };
        setClicked(true);
        dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

        //GA360
        if (mySite.enableGA) {
          AsyncCall.sendRemoveFromCartEvent(item, {
            enableUA: mySite.enableUA,
            enableGA4: mySite.enableGA4,
          });
        }
      };

      return (
        <StyledIconButton
          disabled={disabled}
          color="primary"
          style={{ padding: "0.2rem" }}
          onClick={() => removeItem(rowData)}>
          {miniCartView ? <CloseIcon /> : <DeleteOutlineIcon />}
        </StyledIconButton>
      );
    };

    let columns: TableColumnDef[] = [
      {
        title: "",
        idProp: "orderItemId",
        keyLookup: {
          key: CONSTANTS.thumbnail,
        },
        display: {
          cellStyle: {
            textAlign: "center",
          },
          template: ThumbnailCell,
        },
      },

      {
        title: t("OrderItemTable.Labels.ItemDetails"),
        keyLookup: {
          key: CONSTANTS.name,
        },
        sortable: { fn: itemValCalc },
        display: {
          template: ItemDetailsCell,
        },
      },
      /*{
        title: t("OrderItemTable.Labels.Status"),
        keyLookup: {
          key: CONSTANTS.orderItemInventoryStatus,
        },
        sortable: { fn: oaValCalc },
        display: {
          template: ({ rowData, ...props }) => (
            <>
              {rowData.availableDate === ""
                ? rowData.orderItemInventoryStatus ===
                    INVENTORY_STATUS.available ||
                  rowData.orderItemInventoryStatus ===
                    INVENTORY_STATUS.allocated
                  ? t("CommerceEnvironment.inventoryStatus.Available")
                  : t("CommerceEnvironment.inventoryStatus.OOS")
                : rowData.availableDate <= new Date()
                ? t("CommerceEnvironment.inventoryStatus.Available")
                : rowData.orderItemInventoryStatus !==
                  INVENTORY_STATUS.backordered
                ? t("CommerceEnvironment.inventoryStatus.Available")
                : t("CommerceEnvironment.inventoryStatus.Backordered")}
            </>
          ),
        },
      },*/

      {
        title: "",
        keyLookup: {key: "phantomNote"},
        display: {
          cellStyle: {
            textAlign: "left",
          },
          template: NoteCell,
        },
      },

      {
        title: t("OrderItemTable.Labels.Quantity"),
        keyLookup: {
          key: CONSTANTS.quantity,
        },
        sortable: { numeric: true, fn: quantityCalc },
        display: {
          cellStyle: {
            textAlign: "left",
          },
          template: QuantityCell,
        },
      },
      {
        title: t("OrderItemTable.Labels.Price"),
        keyLookup: {
          key: CONSTANTS.orderItemPrice,
        },
        sortable: { numeric: true, fn: priceCalc },
        display: {
          cellStyle: {
            textAlign: "left",
          },
          template: ({ rowData, ...props }) => (
            <OrderItemPrice rowData={rowData} />
          ),
        },
      },
      {
        title: t("OrderItemTable.Labels.Actions"),
        keyLookup: {
          key: CONSTANTS.orderItemActions,
        },

        display: {
          cellStyle: {
            textAlign: "left",
          },
          template: DeleteActionCell,
        },
      },
    ];

    if (readOnly) {
      columns = columns.filter(
        (col) => col.keyLookup.key !== CONSTANTS.orderItemActions
      );
    }
    if (miniCartView) {
      columns = columns.filter(
        (col) =>
          col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus &&
          col.keyLookup.key !== CONSTANTS.quantity &&
          col.keyLookup.key !== CONSTANTS.orderItemPrice &&
          col.keyLookup.key !== CONSTANTS.orderItemActions
      );
    }
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    mySite,
    miniCartView,
    handleMiniCartClose,
    isRecurringOrderFeatureEnabled,
    contractId,
    dispatch,
    CancelToken,
    readOnly,
    quantityList,
    defaultCurrencyID,
  ]);

  useEffect(() => {
    setQuantityList(initQuantityData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    columns,
    data,
    options,
    labels: {
      labelRowsSelect: t("OrderItemTable.Labels.PageSizeLabel"),
      labelDisplayedRows: t("OrderItemTable.Labels.RowCount"),
      firstTooltip: t("OrderItemTable.Labels.FirstPage"),
      previousTooltip: t("OrderItemTable.Labels.PreviousPage"),
      nextTooltip: t("OrderItemTable.Labels.NextPage"),
      lastTooltip: t("OrderItemTable.Labels.LastPage"),
    },
    t,
    miniCartView,
    handleMiniCartClose,
  };
};

/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
//Custom libraries
import { HOME, SIGNIN } from "../../../constants/routes";
//Redux
import { sessionErrorSelector } from "../../../redux/selectors/error";
import { siteSelector } from "../../../redux/selectors/site";
import {
  RESET_SESSION_ERROR_ACTION,
  CANCEL_SESSION_ERROR_ACTION,
} from "../../../redux/actions/error";
import {
  loginStatusSelector,
  userInitStatusSelector,
  forUserIdSelector,
} from "../../../redux/selectors/user";

//UI
import {
  StyledButton,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { useCSRForUser } from "../../../_foundation/hooks/useCSRForUser";

export const SessionErrorDialog = () => {

  const forUserId = useSelector(forUserIdSelector);
  const mySite = useSelector(siteSelector);

  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] = React.useState<boolean>(false);

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { errorTitleKey, errorMsgKey, handled, errorMessage } = useSelector(
    sessionErrorSelector
  );
  const widgetName = getDisplayName(SessionErrorDialog);
  const dispatch = useDispatch();

  const loginStatus = useSelector(loginStatusSelector);
  const userInitStatus = useSelector(userInitStatusSelector);

  const { handleForUserSessionError } = useCSRForUser();

  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    setOpen(false);
    setRedirect(true);
  };

  const handleCancel = () => {
    setOpen(false);
    let payload = {
      ...payloadBase,
    };
    dispatch(CANCEL_SESSION_ERROR_ACTION(payload));
  };

  useEffect(() => {
    if (userInitStatus && !loginStatus && handled === false) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus, userInitStatus, handled]);

  useEffect(() => {
    setOpen(handled === false && !redirect);
    if (handled === true) {
      dispatch(RESET_SESSION_ERROR_ACTION());
      history.push(HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handled, dispatch, mySite]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (handled === undefined || handled === null || !loginStatus) {
    return <></>;
  } else if (forUserId) {
    handleForUserSessionError(errorMsgKey);
    return <></>;
  } else {
    return (
      (redirect) ?
        <Redirect to={SIGNIN} />
        :
        <StyledDialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="sm"
          open={open}>
          <form
            onSubmit={handleSubmit}
            name="signInForm"
            noValidate>

            <StyledDialogTitle
              title={t(errorTitleKey)}
              onClickHandler={handleCancel}
            />
            <StyledDialogContent>

              <StyledTypography variant="body1">
                {i18n.exists(errorMsgKey) ? t(errorMsgKey) : errorMessage}
              </StyledTypography>

              <StyledDialogActions>

                <StyledButton
                  color="secondary"
                  size="small"
                  onClick={handleCancel}>
                  {t("SessionError.CancelButton")}
                </StyledButton>

                <StyledButton
                  color="primary"
                  size="small"
                  type="submit">
                  {t("SessionError.SubmitButton")}
                </StyledButton>

              </StyledDialogActions>
            </StyledDialogContent>
          </form>
        </StyledDialog>
    );
  }
};

/*
 *==================================================
 * Licensed Materials - ADIACENT
 *
 * 
 *
 * FECOM-67 G.Grandinetti
 *
 *==================================================
 */
import { RootReducerState } from "../reducers";

const contextSelector = (state: RootReducerState) => {
    return state.context;
}

const mroContextSelector = (state: RootReducerState | any) => {
    return state.context?.mro;
}

const isPunchOutSessionSelector = (state: RootReducerState | any) => {
    return state.context?.mro?.punchOutSession || false ;
}

const mroContextCompanySelector = (state: RootReducerState | any) => {
    return state.context?.mro?.company;
}

const mroContextCountrySelector = (state: RootReducerState | any) => {
    return state.context?.mro?.country;
}

const mroContextDefaultPlantSelector = (state: RootReducerState | any) => {
    return state.context?.mro?.defaultPlant;
  };
  

export {
    contextSelector,
    mroContextSelector,
    isPunchOutSessionSelector,
    mroContextCompanySelector,
    mroContextCountrySelector,
    mroContextDefaultPlantSelector,
}
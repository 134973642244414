/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Redux
import { RootReducerState } from "../reducers";

/** ADIACENT G.Grandinetti FEC-95 */
const signoutStatusSelector = (state: RootReducerState) => {
  return state.user?.signout || false;
};

/** ADIACENT G.Grandinetti FECOM-67 */
const contextAttributeSelector = (state: RootReducerState) => {
  return state.user?.details?.contextAttribute;/** array of attributes MBRATTR */
};
const preferredCurrencySelector = (state: RootReducerState) => {
  return state.user?.details?.preferredCurrency;
};
/** FECOM-67 */

const loginStatusSelector = (state: RootReducerState) => {
  return state.user.userLoggedIn || false;
};

const loginErrorMsgSelector = (state: RootReducerState) => {
  return state.user.userLoginErrorMsg;
};
const guestStatusSelector = (state: RootReducerState) => {
  return state.user.isGuest || false;
};
const wcTokenSelector = (state: RootReducerState) => {
  return state.user.WCToken;
};
const wcTrustedTokenSelector = (state: RootReducerState) => {
  return state.user.WCTrustedToken;
};

const logonIdSelector = (state: RootReducerState) => {
  return state.user.details?.logonId || "";
};

const userIdSelector = (state: RootReducerState) => {
  return state.user.userId;
};

const forUserIdSelector = (state: RootReducerState) => {
  return state.user.forUserId;
};

const userNameSelector = (state: RootReducerState) => {
  const firstName = state.user.details?.firstName || "";
  const lastName = state.user.details?.lastName || "";
  const logonId =  state.user.details?.logonId || "logon";
  return { firstName, lastName, logonId };
};

const userInitStatusSelector = (state: RootReducerState) => {
  return state.user.initiatedFromStorage;
};

const userLastUpdatedSelector = (state: RootReducerState) => {
  return state.user.lastUpdated;
};

const registrationStatusSelector = (state: RootReducerState) => {
  return state.user.userRegistration || false;
};

export {
  signoutStatusSelector,
  loginStatusSelector,
  loginErrorMsgSelector,
  guestStatusSelector,
  wcTokenSelector,
  wcTrustedTokenSelector,
  logonIdSelector,
  registrationStatusSelector,
  userNameSelector,
  userIdSelector,
  userInitStatusSelector,
  userLastUpdatedSelector,
  forUserIdSelector,
  contextAttributeSelector,
  preferredCurrencySelector,
};

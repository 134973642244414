import Axios, { AxiosPromise } from "axios";

import { getSite } from "../../../_foundation/hooks/useSite";

const codeservicereq = {
    postCodice72(parameters: any): AxiosPromise<any> {
        const siteInfo = getSite();
        return Axios.post(`/wcs/resources/store/${siteInfo?.storeID}/codeservicereq`, { ...parameters });
    }
}

export  default codeservicereq;
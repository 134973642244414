/**
 * @author Giovanni Grandinetti <g.grandinetti@adiacent.com>
 * 
 * ADIACENT FEC-69
 */
import Axios, { AxiosPromise } from "axios";

import { getSite } from "../../../_foundation/hooks/useSite";

/**AZURE AD */
export const AZUREAD_RESP_TYPE = "code";
export const AZUREAD_RESP_MODE = "fragment";
export const AZUREAD_SCOPE = "openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read";
export const AZUREAD_TMP_STATE = "AZUREAD_TMP_STATE";
export const AZUREAD_AUTHORIZE_URL = "AZUREAD_AUTHORIZE_URL";
export const AZUREAD_CLIENT_ID = "AZUREAD_CLIENT_ID";
export const AZUREAD_SIGNOUT_URL = "AZUREAD_SIGNOUT_URL";

export interface PrintAsReqParam {
    (): string;
}

export interface AuthParam {
    client_id: string;
    authorize_url: string;
    response_type: string;
    redirect_uri: string;
    scope: string;
    response_mode: string;
    state: number;
    nonce: number;
    printAsReqParam: PrintAsReqParam;
}

const azureAdService = {
    loginAzureAd(parameters: any): AxiosPromise<any> {
        const siteInfo = getSite();
        const {
            body
        } = parameters;
        return Axios.post(`/wcs/resources/store/${siteInfo?.storeID}/loginidentity/azuread`, { ...body });
    }
}

export default azureAdService;
/** 
 *
 * @author Giovanni Grandinetti <g.grandinetti@adiacent.com>
 *
 */
import { isArray } from "lodash-es";
import queryString from "query-string"
import { SEARCHTERM , PROFILENAME, SEARCH_TYPE} from "../constants/common";

 const urls = {

    location: (s: string):string => {
        if(s === null || typeof(s) === undefined)return '';
        if(s.includes("?"))  {
          return s.substring(0,s.indexOf("?"));
        }
        return s
    },

    paramsToUrlMapper: (params: any):string => {
      let queryString_obj = {};

      if(params?.searchTerm || params?.query?.searchTerm) {
        queryString_obj[SEARCHTERM] = (params?.searchTerm ? params.searchTerm : params.query.searchTerm).replace(/\s/g, '%20');
      }

      if(params?.profileName || params?.query?.profileName) {
        queryString_obj[PROFILENAME] = params?.profileName ? params.profileName : params.query.profileName;
      }

      if(params?.searchType || params?.query?.searchType) {
        queryString_obj[SEARCH_TYPE] = params?.searchType ? params.searchType : params.query.searchType;
      }

      if(params?.limit || params?.query?.limit) {
        queryString_obj["limit"] = parseInt(params?.limit ? params.limit : params.query.limit);
      }

      if(params?.offset || params?.query?.offset) {
        queryString_obj["offset"] = parseInt(params?.offset ? params.offset : params.query.offset);
      }else{
        queryString_obj["offset"] = 0;
      }

      if(params?.orderBy || params?.query?.orderBy) {
        queryString_obj["orderBy"] = params?.orderBy ? params.orderBy : params.query.orderBy;
      }

      if(params?.query?.facet) {
        queryString_obj["facet"] = params.query.facet;
      }

      if(params?.query?.facetLimit) {
        queryString_obj["facetLimit"] = params.query.facetLimit;
      }

      if(params?.minPrice || params?.query?.minPrice) {
        queryString_obj["minPrice"] = params?.minPrice ? params.minPrice : params.query.minPrice;
      }

      if(params?.maxPrice || params?.query?.maxPrice) {
        queryString_obj["maxPrice"] = params?.maxPrice ? params.maxPrice : params.query.maxPrice;
      }

      return queryString.stringifyUrl(
        {
          url: urls.location(window.location.href),
          query: queryString_obj,
        },
        {
          encode: false,
        }
      );
    },

    urlToParamsMapper: (querys:any, paramsBase:any):any =>{   

      let query_obj = queryString.parse(querys, { decode: false });  
      
      if(query_obj?.facet && (!isArray(query_obj.facet)))  {
        query_obj = {
          ...query_obj,
          facet: new Array(query_obj.facet)
        }
      }
      if(query_obj?.facetLimit && (!isArray(query_obj.facetLimit)))  {
        query_obj = {
          ...query_obj,
          facetLimit: new Array(query_obj.facetLimit)
        }
      }
      if(query_obj?.searchTerm)  {
        const s:any = query_obj?.searchTerm;
        query_obj = {
          ...query_obj,
          searchTerm: s.replaceAll("%20", ' ')
        }
      }

      const query = {...paramsBase, ...query_obj};
      
      let state_update;
      if(query_obj?.facet) {
        state_update = state_update || {};
        state_update["selectedFacets"] = urls.parseFacet(query_obj.facet);
      }

      if(query_obj?.facetLimit) { 
        state_update = state_update || {};
        state_update["selectedFacetLimits"] = query_obj.facetLimit;
      } 

      if(query_obj?.offset) {
        state_update = state_update || {};
        state_update['selectedPageOffset'] = query.offset;
      }
      if(query_obj?.orderBy) {
        state_update = state_update || {};
        state_update['selectedSortOption'] = query.orderBy;
      }
      if(query_obj?.minPrice) {
        state_update = state_update || {};
        state_update['minPrice'] = query.minPrice;
      }
      if(query_obj?.maxPrice) {
        state_update = state_update || {};
        state_update['maxPrice'] = query.maxPrice;
      }

      return {
        parameters: {query: query},
        states: state_update,
      };
    },

    parseFacet: (facet: any) => {
      return facet.reduce( (acc, f) => {
        const decodedFacet = queryString.parse(f)
        const firstKey = Object.keys(decodedFacet)[0];
        const splitDecodedFacet = String(firstKey).split(':');
        acc[f] = splitDecodedFacet[1].replaceAll('"', '');
        return acc
      }, {}) ;
    }
  
  }
  
  export default urls;
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

import { call, put } from "redux-saga/effects";
import { CommerceEnvironment } from "../../../constants/common";
import { LS_LANG_CHANGE_DONE_ACTION } from "../../actions/local-storage";

//Custom
import xstoreconfiguration from "../../../configs/xstoreconf";

export function* persistLang2LocalStorage(action: any) {
  const payload = action.payload;
  const newLang = CommerceEnvironment.languageMap[payload?.newLangId];

  try{
    const res = yield call(xstoreconfiguration.retrievePublicConfiguration_promise, payload?.storeId, payload?.newLangId);
    xstoreconfiguration.updateSessionStorage(res, payload.newLangId);
  }catch(ex)  {
    console.error("Error update XSTORCONF with language : " + payload.newLangId)
  }

  yield put(LS_LANG_CHANGE_DONE_ACTION({ newLang }));
}

/**
 *==================================================
 * 
 *
 * Adiacent
 *
 * G.Grandinetti
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import { MroSystemOperation } from "./reducerStateInterface";
import { MRO_SYS_IS_LOADING_ACTION, MRO_SYS_IS_CHANGE_CONTRY_LOADING_ACTION } from "../actions/mroSystemOperation";

/**
 * mroSystemOperationReducer reducer
 */
const mroSystemOperationReducer = createReducer(initStates.mroSystemOperation, (builder) => {
  
  builder.addCase(
    MRO_SYS_IS_LOADING_ACTION,
    (state: MroSystemOperation | any, action: AnyAction) => {
     state.isLoading = action.payload.isLoading
    }
  );

  builder.addCase(
    MRO_SYS_IS_CHANGE_CONTRY_LOADING_ACTION,
    (state: MroSystemOperation | any, action: AnyAction) => {
     state.isChangeCountryLoading = action.payload.isChangeCountryLoading
    }
  );

});
export default mroSystemOperationReducer;
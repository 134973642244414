export const ferreroBase = {

  primary: {
    main: "#0081CF",
    light: "#1EC79F",
    dark: "#007055",
  },

  secondary: {
    main: "#845EC2",
    light: "",
    dark: "",
  },

  text: {
    primary: "#4E4637",
    secondary: "#5E6977",
    disabled: "#B5BECA",
    highlight: "#f44336",
    alert: "#C10C0D",
    expandedMenu: "#FFFFFF",
  },

  border: {
    main: "#cccccc",
    hover: "#F0F4F7",
    alert: "#C10C0D",
  },

  divider: {
    dark: "#4C5256",
  },

  background: {
    default: "#F4F4F4",
    paper: "#FFFFFF",
    transparency: "CC",
  },

  disabled: {
    background: "#F0F4F7",
  },

};

export const ferreroTest = {}
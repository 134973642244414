import Axios, { AxiosPromise } from "axios";

import { getSite } from "../../../_foundation/hooks/useSite";

const mroService = {
    
    getRoles(storeId:string):AxiosPromise<any> {
        return Axios.get(`/wcs/resources/store/${storeId}/person/@self/roles`);
    },

    getVendorList(storeId:string): AxiosPromise<any> {
        return Axios.get(`/wcs/resources/store/${storeId}/btp/vendorlist`);
    },

    getAllVendorsByCountry(storeId:string, country:string): AxiosPromise<any> {
        return Axios.get(`/wcs/resources/store/${storeId}/vendor/allByCountry?country=${country}`);
    },

    getVisibleDealerAndPlantForCatEntry(storeId:string,catentryid:string,ecccode:string,country:string):AxiosPromise<any> {
        return Axios.get(`/wcs/resources/store/${storeId}/vendor/visible?catentryid=${catentryid}&ecccode=${ecccode}&country=${country}`);
    },

    putChangeCountry(country:string):AxiosPromise<any> {
        const siteInfo = getSite();
        return Axios.put(`/wcs/resources/store/${siteInfo?.storeID}/superuser/changeCountry`, {country: country});
    },
}


export default mroService;
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */

import theme from './ferrero/ferrero-theme'

// TODO switch theme
const CurrentTheme = theme;


export { CurrentTheme };

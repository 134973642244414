/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import userContextService from "../../../_foundation/apis/transaction/userContext.service";
//Redux
import {
  USER_CONTEXT_CHANGE_COUNTRY_SUCCESS_ACTION,
  USER_CONTEXT_REQUEST_ERROR_ACTION,
  USER_CONTEXT_REQUEST_SUCCESS_ACTION,
} from "../../actions/context";
import { CHANGE_CURRENCY_SUCCESS_ACTION } from "../../actions/user";
import mroService from "../../../components/apis/transaction/mro.service";
import { GENERIC_ERROR_ACTION } from "../../actions/error";
import { GENERIC_ERROR_CODE, GENERIC_ERROR_KEY } from "../../../constants/errors";

export function* getUserContext(action: any) {
  const payload = action.payload;
  try {
    const response = yield call(userContextService.getContextData, payload);

    let userPayload = response.data;
    if (payload?.widget) {
      userPayload["widget"] = payload.widget;
    }
    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION(userPayload));
  } catch (e) {
    yield put(USER_CONTEXT_REQUEST_ERROR_ACTION(e));
  }
}

export function* changeContextCountry(action: any) {
  const payload = action.payload;
  
  try {
    const countryCode = action.payload.countryCode;
    const response = yield call(mroService.putChangeCountry, countryCode);
    if(response.status === 202)   {
      yield put(USER_CONTEXT_CHANGE_COUNTRY_SUCCESS_ACTION(payload));
      yield put(CHANGE_CURRENCY_SUCCESS_ACTION(payload))
    }else{
      throw new Error('Fail change contry, respose status : ' + response?.status);
    }  
  } catch (e) {
    const errorObj = {
      errorKey: GENERIC_ERROR_KEY,
      errorCode: GENERIC_ERROR_CODE,
      handled: false,
      errorMessage: e?.toString() + " || ERROR to change country. Contact the Adminisrator.",
    };
    yield put (GENERIC_ERROR_ACTION(errorObj));
  }
}
/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/order";

const ADD_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_ADD_REQUESTED
);
const REMOVE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_REMOVE_REQUESTED
);
const UPDATE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.ITEM_UPDATE_REQUESTED
);
/**
 * The action set fetching state to true.
 */
const FETCHING_CART_ACTION = createAction<any, string>(
  ACTIONTYPES.CART_FETCHING_REQUESTED
);
const GET_CART_ACTION = createAction<any, string>(
  ACTIONTYPES.CART_GET_REQUESTED
);
const COPY_CART_ACTION = createAction<any, string>(ACTIONTYPES.COPY_CART);
const COPY_CART_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.COPY_CART_SUCCESS
);
const COPY_CART_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.COPY_CART_ERROR
);

const GET_SHIPINFO_ACTION = createAction<any, string>(
  ACTIONTYPES.SHIPINFO_GET_REQUESTED
);

const GET_SHIPMODES_ACTION = createAction<any, string>(
  ACTIONTYPES.SHIPMODES_GET_REQUESTED
);

const UPDATE_SHIPMODE_ACTION = createAction<any, string>(
  ACTIONTYPES.SHIPMODE_UPDATE_REQUESTED
);

const GET_PAYMETHODS_ACTION = createAction<any, string>(
  ACTIONTYPES.PAYMETHODS_GET_REQUESTED
);

const RESET_CART_ACTION = createAction(ACTIONTYPES.CART_RESET_REQUESTED);

const FETCH_ALL_ORDERS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALL_ORDERS
);
const FETCH_ORDERS_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALL_ORDERS_SUCCESS
);

const FETCH_ORDERS_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALL_ORDERS_ERROR
);

const FETCH_ALLOWABLE_SHIPMODES_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES
);
const FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES_SUCCESS
);
const FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_SHIPMODES_ERROR
);

const SET_ACTIVE_INPROGRESS_ORDER_ACTION = createAction<any, string>(
  ACTIONTYPES.SET_ACTIVE_INPROGRESS_ORDER
);

const RESET_ACTIVE_INPROGRESS_ORDER_ACTION = createAction(
  ACTIONTYPES.RESET_ACTIVE_INPROGRESS_ORDER
);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM
);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION = createAction<
  any,
  string
>(ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS);

const FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION = createAction<
  any,
  string
>(ACTIONTYPES.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR);

const REMOVE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.REMOVE_INPROGRESS_ORDER_ITEM
);
const UPDATE_INPROGRESS_ORDER_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.UPDATE_INPROGRESS_ORDER_ITEM
);

const FETCH_ALLOWABLE_PAYMETHODS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS
);
const FETCH_ALLOWABLE_PAYMETHODS_S_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS_S
);
const FETCH_ALLOWABLE_PAYMETHODS_F_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_ALLOWABLE_PAYMETHODS_F
);

/** ADIACENT FECOM-75 G.Grandinetti */
const COMPLETE_PUNCHOUT_ACTION = createAction<any, string>(
  ACTIONTYPES.COMPLETE_PUNCHOUT
);

/** ADIACENT FECOM-161 G.Grandinetti */
const CALCULATE_SHIPPING_ACTION = createAction<any, string>(
  ACTIONTYPES.CALCULATE_SHIPPING
);

const CUSTOM_FETCH_CART_ACTION = createAction<any, string>(
  ACTIONTYPES.CUSTOM_FETCH_CART
);

const CALCULATE_SHIPPING_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.CALCULATE_SHIPPING_SUCCESS
);

const CALCULATE_SHIPPING_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.CALCULATE_SHIPPING_ERROR
);

const COMPLETE_PUNCHOUT_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.COMPLETE_PUNCHOUT_ERROR
);

export {
  ADD_ITEM_ACTION,
  REMOVE_ITEM_ACTION,
  UPDATE_ITEM_ACTION,
  FETCHING_CART_ACTION,
  GET_CART_ACTION,
  GET_SHIPINFO_ACTION,
  GET_SHIPMODES_ACTION,
  UPDATE_SHIPMODE_ACTION,
  GET_PAYMETHODS_ACTION,
  COPY_CART_ACTION,
  COPY_CART_SUCCESS_ACTION,
  COPY_CART_ERROR_ACTION,
  RESET_CART_ACTION,
  FETCH_ALL_ORDERS_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDERS_ERROR_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION,
  SET_ACTIVE_INPROGRESS_ORDER_ACTION,
  RESET_ACTIVE_INPROGRESS_ORDER_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION,
  REMOVE_INPROGRESS_ORDER_ITEM_ACTION,
  UPDATE_INPROGRESS_ORDER_ITEM_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_F_ACTION,
  COMPLETE_PUNCHOUT_ACTION,
  COMPLETE_PUNCHOUT_ERROR_ACTION,
  CALCULATE_SHIPPING_ACTION,
  CUSTOM_FETCH_CART_ACTION,
  CALCULATE_SHIPPING_SUCCESS_ACTION,
  CALCULATE_SHIPPING_ERROR_ACTION
};

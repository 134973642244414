//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/mroSystemOperation";

export const MRO_SYS_IS_LOADING_ACTION = createAction<any, string>(
  ACTIONTYPES.IS_LOADING
);

export const MRO_SYS_IS_CHANGE_CONTRY_LOADING_ACTION = createAction<any, string>(
  ACTIONTYPES.IS_CHANGE_CONTRY_LOADING
);
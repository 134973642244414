/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// user context

export const USER_CONTEXT_REQUESTED = "USER_CONTEXT_REQUESTED";
export const USER_CONTEXT_REQUEST_SUCCESS = "USER_CONTEXT_REQUEST_SUCCESS";
export const USER_CONTEXT_REQUEST_ERROR = "USER_CONTEXT_REQUEST_ERROR";

/** with these we change the mroCountry in mro Context FECOM-441*/
export const USER_CONTEXT_CHANGE_COUNTRY = "USER_CONTEXT_CHANGE_COUNTRY";
export const USER_CONTEXT_CHANGE_COUNTRY_SUCCESS = "USER_CONTEXT_CHANGE_COUNTRY_ACTION_SUCCESS";



/*
 *---------------------------------------------------
 *  ADIACENT 
 *  G.Grandinetti
 *
 * 
*/
import { sessionStorageUtil, localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { LOCALE } from "../../../_foundation/constants/common";
import { VENDOR_LIST } from "../../../constants/btp";
import {CommerceEnvironment} from "../../../constants/common";
import { isNumber } from "lodash-es";

enum FunctionalAttrib {
  /** Type */
  DESCRIPTIVE = "Descriptive",
  DEFINING = "Defining",
  /** Identifier */
  CODICE72 = "CODICE72",
  UNSPSCCODE = "UNSPSCCODE",
  UNSPSCDESC = "UNSPSCDESC",
  MANUFACTURERATTRIBUTES = "MANUFACTURERATTRIBUTES",
  CODICEFORNITOREECC = "CODICEFORNITOREECC",
  ASSOCIATED_COMPANY = "ASSOCIATED_COMPANY",
  STATUS = "STATUS",
  COMMODITY_CODE_ARIBA = "COMMODITY_CODE_ARIBA",
  MINIMUM_QUANTITY = "MINIMUM_QUANTITY",
  LEAD_TIME = "LEAD_TIME",
  PIM_VALIDATED = "PIM_VALIDATED",
  VENDOR = "VENDOR",
  VENDORDESC = "VENDORDESC",
  VENDOR_CODE = "VENDOR_CODE",
  VENDOR_DESC = 'VENDOR_DESC',
  MDG_CODE = 'MDG_CODE',
  MNF_PARTNUM = 'MNF_PARTNUM',
  VALID_STATUS = 'A4'
}

const productAttributeUtils = {


    getAttrValueByIdentifier: (attr:Array<any>, identifier:string):string|undefined => {
      const values = productAttributeUtils.getAttrValuesByIdentifier(attr,identifier);
      if(values && Array.isArray(values) && values.length>0) return `${values[0]}`;
      return undefined;
    },

    /**
     * 
     * @param attr 
     * @param identifier 
     * @returns an Array of string
     * 
     * retrieve values by identifier
     */
    getAttrValuesByIdentifier: (attr:Array<any>, identifier:string):Array<any> => {
      let result:Array<any> = [];
      if(attr && attr.length > 0)  {
        
        attr.filter(attrs => attrs.identifier === identifier)
          .map(attr => attr.values)
          .forEach(vals => {
            vals.map(val => val.value)
              .forEach(v => {
                if(Array.isArray(v)) {
                  v.forEach(vv => result.push(vv))
                }else{
                  result.push(v)
                }
              })
          })
      }
      return result;
    },

    /** 
     * prevent the TypeError 
     * in case there is something wrong in data 
     * when we aspect single unique value
    */
    checkSingleAttribValue: (att:any):boolean => {
      const attribValue = att?.values[0]?.value;
      return (attribValue && (typeof attribValue === 'string'));
    },  

    /** 
    * Extract attrVal identifier includes counry 
    */
    // TODO da eliminare
    getAttributeValIncludesCounty: (att:any, country:string):string|undefined => {
      let identifier = att?.values[0]?.identifier;
      if(typeof identifier === 'string') {
        return att.values[0]?.value;
      }else{
        for(let i=0;i<identifier.length;i++)   {
          if(identifier[0].includes(country)) {
            return att.values[0]?.value[i];
          }
        }
      }
    },

    /**
    *  get dealerCode from vendor list by (manufacturerCode, country)
    */
   // TODO da eliminare
    getDealerCode: (eccCode:string, country:string):string|undefined => {
      let dealerCode:string|undefined = undefined;
      if(eccCode && eccCode !== "" && 
          country && country !== "")   {     
        const vendors:any = sessionStorageUtil.get(VENDOR_LIST);
        if(vendors?.vendorList)  {
           dealerCode = vendors.vendorList.filter(v => {
                              const ecc:string = v?.manufacturerCode;
                              const ctr:string = v?.country;
                              return (ecc && ctr) ?
                                     (ecc.trim() === eccCode &&
                                      ctr.trim() === country) : false;
                            }).map(v=>v.dealerCode)[0];
        }
        return dealerCode;
      }
    },

    /**
    *  check if vendor is preferred  by (dealerCode, country)
    */
    isDealerPreferred: (mfCode: string, vendor:string, country:string):boolean => {
      const vendors:any = sessionStorageUtil.get(VENDOR_LIST);
      return vendors?.vendorList ?
              vendors?.vendorList.find(v => 
                v?.manufacturerCode.trim().toLowerCase() === String(mfCode).trim().toLowerCase() &&
                v?.dealerCode.trim().toLowerCase() === String(vendor).trim().toLowerCase() && 
                v?.country.trim().toLowerCase() === String(country).trim().toLowerCase())?.preferred
              : false
    },   
    /**
    *  check if vendor is preferred  by (dealerCode, country)
    */
    isDealerGlobal: (mfCode: string, vendor:string, country):boolean => {
      const vendors:any = sessionStorageUtil.get(VENDOR_LIST);
      return vendors?.vendorList ?
             vendors?.vendorList.find(v => 
              v?.manufacturerCode.trim().toLowerCase() === String(mfCode).trim().toLowerCase() &&
              v?.dealerCode.trim().toLowerCase() === String(vendor).trim().toLowerCase() && 
                v?.country.trim().toLowerCase() === String(country).trim().toLowerCase())?.global
              : false
    }, 
    
    getAttrValIdentifierByTypeCountry: (attr: any, type:string, country:string):any => {
      const val = attr?.values[0];
      if(typeof val.identifier === 'string' &&
          val.identifier.startsWith(type) &&
          val.identifier.substring(3,5).toLowerCase() === country.toLowerCase().trim()) {      
          return (typeof val.value === 'string') ? val.value : val.value[0];
      }else{
        for(let i=0;i<val.identifier.length;i++)   {
          const idf = val.identifier[i];
          if(typeof idf === 'string' &&
              idf.startsWith(type) &&
              idf.substring(3,5).toLowerCase() === country.toLowerCase().trim()) {      
            return val.value[i]
          }
        }
      }
    },
    getVendorLT: (attr: any, country:string):number|undefined => {
      const lt = Math.round(productAttributeUtils.getAttrValIdentifierByTypeCountry(attr, 'LT', country));
      return isNumber(lt) ? lt : undefined;
    },
    getVendorMQ: (attr: any, country:string):number|undefined => {
      const mq = Math.round(productAttributeUtils.getAttrValIdentifierByTypeCountry(attr, 'MQ', country));
      return isNumber(mq) ? mq : undefined;
    },
    
    /**
    * extract value from raw attrVal 
    * base on dealer code
    */
    // TODO obsolete - to remove
    extractValByDealer: (attrVal:string|undefined, dealer:string):number|undefined => {
      let result:any = undefined;
      if(attrVal && attrVal !== "" &&
        dealer && dealer !== "")   {
        if(!attrVal.includes(dealer)) return undefined;
        if(attrVal.includes("$$")) {
          result = attrVal.split("$$")
                          .filter(v=>v.includes(dealer))
                          .map(v=>v.replace(`${dealer}_`,""))[0];
        }else{
          result = attrVal.replace(`${dealer}_`,"");
        }
      }
      return isNaN(result) ? undefined : result;
    },

    /**
     * calculate delivery date
     */
    getDeliveryDate: (leadTimeDays:number):string => {
      let d = new Date();
      let days:number;
      if(Number.isInteger(leadTimeDays))  {
        days = leadTimeDays;
      }else{
        days = Math.ceil(leadTimeDays);
      }
      
      while(days > 0) {
        d.setDate(d.getDate() + 1);
        if(! (d.getDay() === 0 || d.getDay() === 6) )  {
          days--;
        }
      }
      
      let locale = localStorageUtil.get(LOCALE); 
      locale = (locale ? locale: CommerceEnvironment.defaultLang).replace("_","-");
      return d.toLocaleDateString(locale);
    }
};


export {productAttributeUtils, FunctionalAttrib};
export default productAttributeUtils;
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
import codeservicereq from "../../../components/apis/transaction/codeservicereq.service";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
//Redux
import * as ACTIONS from "../../action-types/catalog";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";

//Utils
import urls from "../../../utils/urls"
import { VISIBLE_FACET_VENDOR_IN_COUNTRY_SUCCESS_ACTION } from "../../actions/catalog";
import mroService from "../../../components/apis/transaction/mro.service";
import { GENERIC_ERROR_CODE, GENERIC_ERROR_KEY } from "../../../constants/errors";
import { GENERIC_ERROR_ACTION } from "../../actions/error";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      productsService.findProductsUsingGET,
      payload.parameters
    );
    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });

    
    if(!(payload.parameters.query.searchTerm !== undefined && response.data.total === 1)){
      let updatedURL = yield call(urls.paramsToUrlMapper ,{...payload.parameters});
      window.history.pushState({}, '', updatedURL);
    }
    

  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      productsService.findProductsUsingGET,
      payload.parameters
    );
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}


export function* makeCodice72Request(action: any) {

  try {
    let response = yield call(codeservicereq.postCodice72, action.payload);
    const time = response?.data?.codeReqTime;
  
    const successMessage = {
      key: "success-message.CODICE72_REQUEST_SUCCESS",
      messageParameters: {time: time}
    };  
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
  } catch (error)  {
    console.group("Error codice72 request");
      console.error(error);
    console.groupEnd()
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
  
}



export function* getVisibleFacetVendorInUserCountry(action: any)  {
  try{
    const countryCode:string = action.payload.mroCountry;
    const storeID:string = action.payload.storeID;
    const response = yield call(mroService.getAllVendorsByCountry, storeID, countryCode);
    if(response.status === 200)   {
      yield put(VISIBLE_FACET_VENDOR_IN_COUNTRY_SUCCESS_ACTION(response.data));
    }else{
      throw new Error('Fail to get facet visible vendors, respose status : ' + response?.status);
    }
  }catch(error:any)   {
    const errorObj = {
      errorKey: GENERIC_ERROR_KEY,
      errorCode: GENERIC_ERROR_CODE,
      handled: false,
      errorMessage: error?.toString() + " || ERROR facet visible vendors. Contact the Adminisrator.",
    };
    yield put (GENERIC_ERROR_ACTION(errorObj));
  }
}

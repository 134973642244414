/**USER ROLE */
export const REGISTERED_USER_ROLES = "REGISTERED_USER_ROLES";

export const MRO_REGISTERED_USER_ROLE_ID = "-29";
export const MRO_REGISTERED_USER_ROLE_NAME = "Registered Customer";

export const MDG_ADVANCED_USER_ROLE_ID = "10001";
export const MDG_ADVANCED_USER_ROLE_NAME = "MDG Advanced";

export const SUPER_USER_ROLE_NAME = "Super User";

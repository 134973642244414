import Axios, { AxiosPromise } from "axios";
import {
    CartApi,
} from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { site } from "../../../_foundation/constants/site";
import { getSite } from "../../../_foundation/hooks/useSite";

const cartApiInstance = new CartApi(undefined, site.transactionContext);

const punchout = {
    

    complete(parameters: any): AxiosPromise<any> {
        const siteInfo = getSite();
        return Axios.post(`/wcs/resources/store/${siteInfo?.storeID}/cart/punchout`, {}, {timeout: 180000});     
    },

    cXML(parameters: any): AxiosPromise<any> {
        const siteInfo = getSite();
        const orderId = parameters?.orderId;
        return Axios.get(`/wcs/resources/store/${siteInfo?.storeID}/order/${orderId}/cxml`, {timeout: 180000});
    },

    calculate(parameters: any): AxiosPromise<any>|undefined {
        const siteInfo = getSite();
        const storeID = siteInfo ? siteInfo.storeID : "";
        const orderId = parameters?.orderId;
        if ( storeID && orderId )  {
            return cartApiInstance.cartCalculateOrder2(storeID, orderId, "application/json", {calculationUsageId: "-2"} );
        }
        return undefined;
    }
}


export default punchout;